.App {
  text-align: center;
  background-color: rgb(249, 249, 249);
  overflow-x: hidden;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-header {
  background-color: rgb(249, 249, 249);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(195, 35, 35);
}

.header {
  display: flex; 
  align-items: center;
  justify-content: space-between;
  background-color: #41D3E1;
  z-index: 1;
  top: 0;
  position: sticky;
  height: 6rem;
  transition-property: all; 
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  padding: 0 2rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.header.down {
  top: -6rem;
}

.logo-cotainer {
  /* background-color: white; */
  text-align: end;
  height: 6rem;
  padding-right: 20px;
  display: flex;
  align-items: center;
  /* width: 200px; */
}

.logo {
  width: 6rem;
  height: auto;
}

.nav-items {
  width: calc(100% - 200px);
  display: flex;
  justify-content: flex-end;
}

.nav-items > ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-items > ul > li {
  padding: 20px;
  margin: 10px;
}

.footer {
  margin: 0;
  width: 100%;
  text-align: center;
  padding: 2rem;
  background-color: #333;
  color: white;
}

.footer > i {
  padding: 0 5px;
  font-size: 1.2rem;
}

.footer > strong {
  padding-left: 5px;
  font-size: 1.1rem;
}

.footer > strong > span {
  color: #ff6b6b;
}

.link {
  color: black;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.1rem;
  transition: color 0.3s ease;
}

.link:hover {
  color: white;
}

.home-content {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading {
  color: #41D3E1;
  font-size: 3.5rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
}

.home-heading {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./final2.jpeg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center; 
  background-size: cover;
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-description {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./final2.jpeg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center; 
  background-size: cover;
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 2rem;
  padding-bottom: 10px;
}

.home-description > div {
  background: white;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: justify;
}

.home-description > div > div {
  margin: 3rem 3rem 0 3rem;
  font-size: 1.1rem;
  line-height: 1.8;
}

.res-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: center;
  padding: 2rem 4rem;
  gap: 3rem;
  background-color: rgb(249, 249, 249);
  width: 100%;
  max-width: 100%;
}

.application-card {
  width: 100%;
  margin: 0;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.application-card:hover {
  transform: translateY(-5px);
}

.application-card > p {
  padding: 20px;
  text-align: justify;
  line-height: 1.6;
  color: #333;
}

.res-logo {
  width: 100%;
  height: 140px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}

/* Mobile Responsive Styles */
@media screen and (max-width: 768px) {
  .header {
    height: auto;
    padding: 1rem;
  }

  .logo-cotainer {
    height: auto;
    padding-right: 0;
    width: auto;
  }

  .logo {
    width: 4rem;
  }

  .nav-items {
    width: 100%;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #41D3E1;
    padding: 1rem;
  }

  .nav-items.active {
    display: block;
  }

  .nav-items > ul {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .nav-items > ul > li {
    margin: 1rem 0;
    padding: 0;
  }

  .heading {
    font-size: 32px;
  }

  .home-content {
    padding: 0 1rem;
  }

  .res-container {
    grid-template-columns: 1fr;
    padding: 0 1rem;
    gap: 1rem;
  }

  .application-card {
    width: 100%;
  }

  .home-heading,
  .home-description {
    background-attachment: scroll;
    background-size: cover;
  }

  .home-description {
    min-height: 100vh;
    height: auto;
    width: 100%;
    padding: 0;
    justify-content: center;
  }

  .home-description > div {
    width: 100%;
    height: auto;
    min-height: auto;
    padding: 0;
    margin: 0;
  }

  .home-description > div > div {
    margin: 0;
    padding: 1rem;
    font-size: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .heading {
    font-size: 24px;
  }

  .home-heading,
  .home-description {
    background-size: cover;
    min-height: 100vh;
    height: auto;
  }

  .footer {
    font-size: 0.9rem;
  }

  .res-logo {
    height: 160px;
  }

  .home-description {
    padding: 0;
  }

  .home-description > div {
    margin: 0;
    padding: 1rem;
  }

  .home-description > div > div {
    margin: 0;
    padding: 1rem;
    font-size: 0.95rem;
    line-height: 1.6;
  }
}

/* Add hamburger menu styles */
.menu-toggle {
  display: none;
  cursor: pointer;
  padding: 0.5rem;
}

.menu-toggle span {
  display: block;
  width: 25px;
  height: 3px;
  background-color: #000;
  margin: 5px 0;
  transition: 0.3s;
}

@media screen and (max-width: 768px) {
  .menu-toggle {
    display: block;
  }
}